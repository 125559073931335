<template>
  <div>
    <primaryTextfield
      :fieldAttrInput="fieldAttrInput"
      :label="title"
      v-model="dateTime"
      @click="showDateTimePicker"
      :hideDetails="true"
    ></primaryTextfield>
    <v-dialog
      content-class="timePickerDialog pt-3"
      v-model="showDialog"
      max-width="700"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <v-date-picker
                v-model="date"
                :allowed-dates="allowedDates"
              ></v-date-picker>
            </v-col>
            <v-col>
              <v-time-picker v-model="time" format="24hr"></v-time-picker>
            </v-col>
          </v-row>
          <!-- <fieldsRows
                      :templateContent="projectInfo"
                      @field-value-changed="switchProject(...arguments)"
                    ></fieldsRows>-->
        </v-card-text>
        <v-card-actions>
          <primaryButton
            type="button"
            @click="updateValue()"
            label="OK"
            icon="mdi-check"
            color="primary"
            id="timePickerOk"
          ></primaryButton>
          <primaryButton
            type="button"
            @click="emitOk()"
            label="Cancel"
            icon="mdi-cancel"
            color="secondary"
             id="timePickerCancel"
          ></primaryButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
export default {
  props: [
    "unixtimeStamp",
    "title",
    "value",
    "fieldAttrInput",
    "mindate",
    "maxDate",
  ],
  data() {
    return {
      datetime: "",
      showDialog: false,
      localValue: this.value,
    };
  },
  components: { primaryTextfield, primaryButton },
  // watch: {
  //   date(newVal) {
  //     console.log(newVal);
  //   },
  // },
  computed: {
    dateTime() {
      return this.timeConverter(this.value);
    },
    dateObject() {
      // var date = new Date(this.localValue);
      // to convert the minutes to milliseconds
      //  date = new Date(date.valueOf() * 1000);
      return new Date(this.localValue * 1000);
    },
    date: {
      get() {
        var year = this.dateObject.getFullYear();
        var month = ("0" + (this.dateObject.getMonth() + 1)).slice(-2);
        var day = ("0" + this.dateObject.getDate()).slice(-2);

        let date = year + "-" + month + "-" + day;
        return date;
      },
      set(date) {
        this.updateLocalValue(date, this.time);
      },
    },
    time: {
      get() {
        var hour = ("0" + this.dateObject.getHours()).slice(-2);
        // var hour = ("0" + this.dateObject.getUTCHours()).slice(-2);

        var min = this.dateObject.getMinutes();
        min = this.fixMinFormat(min);
        let time = hour + ":" + min;
        return time;
      },
      set(time) {
        this.updateLocalValue(this.date, time);
      },
    },
  },
  methods: {
    allowedDates(val) {
      let show = false;
      if (typeof this.mindate != "undefined" && this.mindate == 0) {
        const today = new Date();
        const allDays = new Date(val);
        const todayConstruct =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        show = false;
        if (allDays.getTime() > today.getTime()) {
          show = true;
        } else if (todayConstruct == val) {
          show = true;
        }
      } else if (typeof this.maxDate != "undefined" && this.maxDate == 0) {
        const today = new Date();
        const allDays = new Date(val);
        const todayConstruct =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        show = false;
        if (allDays.getTime() < today.getTime()) {
          show = true;
        } else if (todayConstruct == val) {
          show = true;
        }
      } else {
        show = true;
      }
      return show;
    },
    fixMinFormat(min) {
      if (min < 10) {
        min = "0" + min;
      } else {
        min = min + "";
      }
      return min;
    },
    updateLocalValue(date, time) {
      var d = date + "T" + time + ":00.000";
      // this.localValue = new Date(d).valueOf() / 1000;
      let timeList = new Date(d).valueOf() / 1000;
      //this.localValue = timeList-7200;
      this.localValue = timeList;
    },
    updateValue() {
      //   var date = this.date;
      //   var time = this.time;
      //   console.log(date,time);
      //  // var newdate = date.split("-").reverse().join("-");
      //   var d = date + "T" + time + ":00.000Z";
      //   console.log(d)
      //   let newVal = new Date(d).valueOf()/1000;
      //   console.log(newVal);
      this.$emit("input", this.localValue);
      this.showDialog = false;
    },
    showDateTimePicker() {
      this.showDialog = true;
    },
    emitOk() {
      this.showDialog = false;
    },
    timeConverter(UNIX_timestamp) {
      var a = new Date(UNIX_timestamp * 1000);
      //var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      var year = a.getFullYear();
      var month = ("0" + (a.getMonth() + 1)).slice(-2);
      var day = ("0" + a.getDate()).slice(-2);
      //var hour = ("0" + a.getHours()).slice(-2);
      var hour = ("0" + a.getHours()).slice(-2);

      var min = a.getMinutes();
      min = this.fixMinFormat(min);
      var time = day + "." + month + "." + year + " " + hour + ":" + min;
      return time;
    },
  },
};
</script>
